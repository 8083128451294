import React from "react"
import { graphql } from "gatsby"
import PageSection from "../components/pageSection"
import Header from "../components/header"
import Footer from "../components/footer"

const WelcomePage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.title)
    .map(edge => <PageSection key={edge.node.id} post={edge.node} notitle="test" />)

  return (
    <div>
      <Header />
      <div className="content">
        <h1>Welcome</h1>
        <div className="inner">
          {Posts}
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default WelcomePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\//home/"} },
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          excerpt
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
